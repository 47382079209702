.header {
    position: relative;
    background: linear-gradient(90deg, #e6eaff 0%, #b3bfff 100%);

    .site__btns {
        @include absoluteCenterX();
        display: flex;
        align-items: flex-start;
        justify-content: center;
        z-index: 1;
        bottom: 90px;

        &:after {
            content: "";
            position: absolute;
            bottom: -139%;
            left: 25.6%;
            width: 84.31px;
            height: 50.01px;
            background-image: url(../img/svg/curve-arrow.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__centered {
        position: relative;
        width: 100%;
        max-width: 1308px;
        margin: 0 auto;
        padding: 122px 16px 190px;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 22px;
        right: 23px;
        left: 23px;
    }

    &__menu-btn {
        @include absoluteCenterY();
        display: block;
        z-index: 5;
        right: 2px;
        width: 40px;
        height: 40px;
        border: none;
        padding: 0;
        background: none;
        user-select: none;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            height: 3px;
            transition: background-color $animation-time ease-in-out;
            margin-top: -4px;
            background: $elements-purple;
            font-size: 0;
            border-radius: 3px;

            &:before {
                @include transform-translate(0, -10px);
            }

            &:after {
                @include transform-translate(0, 10px);
            }

            &:before, &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 3px;
                -webkit-transition: -webkit-transform $animation-time, background $animation-time;
                transition: transform $animation-time, background $animation-time;
                background: $elements-purple;
            }
        }

        &.opened {

            span {
                background-color: transparent;

                &:before {
                    transform: translateY(0) rotate(45deg);
                }

                &:after {
                    transform: translateY(0) rotate(-45deg);
                }
            }
        }
    }

    &__menu {
        @include transform-translate(-100%, 0);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 96px 33px 16px 33px;
        transition: transform $animation-time ease-in-out;
        overflow-y: scroll;
        background: #fff;
        line-height: 1;

        a {
            @include main-font-heavy;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 21px;
            color: $text-purple;
            text-transform: uppercase;
        }

        &.opened {
            @include transform-translate(0, 0);
        }
    }

    &__hero-title {
        @include main-font-heavy;
        position: relative;
        z-index: 1;
        margin-bottom: 17px;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: center;

        span {
            @include main-font-book;
            display: block;
        }

        img {
            margin-left: 7px;
            width: 62px;
        }
    }

    &__hero-subtitle {
        position: relative;
        z-index: 1;
        margin-bottom: 41px;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
    }

    &__hero-pic {
        position: relative;
        z-index: 0;
        width: 327px;
        margin: 0 auto;

        img {
            position: relative;
            z-index: 2;
            width: 100%;
            height: auto;
        }
    }

    &__hero-deco {
        @include absoluteCenter;
        z-index: 0;
        width: 199.2%;
        margin-top: -20%;
        margin-left: 30.4%;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.logo {
    width: 52px;
    margin: 0;

    img {
        width: 100%;
        height: auto;
    }
}

@media (min-width: $tablet-landscape) {
    .header {
        .site__btns {
            position: relative;
            bottom: 0;
            left: auto;
            transform: none;

            &:after {
                bottom: -158.2%;
                left: 138px;
                width: 138.31px;
                height: 82.01px;
            }
        }

        &__centered {
            padding: 203px 16px 168px;
        }

        &__top {
            top: 16px;
            right: 15px;
            left: 15px;
        }

        &__menu-btn {
            display: none;
        }

        &__menu {
            display: inline-block;
            position: relative;
            z-index: 10;
            width: auto;
            height: auto;
            padding: 0;
            transform: none;
            background: transparent;

            a {
                transition: border-color $animation-time ease;
                margin-right: 37px;
                margin-bottom: 0;
                border-bottom: 1px solid transparent;

                &:hover {
                    border-color: $text-purple;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__hero {
            display: flex;
            justify-content: space-between;
        }

        &__hero-info {
            text-align: left;
        }

        &__hero-title {
            margin-bottom: 22px;
            font-size: 82px;
            line-height: 80px;
            text-align: left;

            img {
                margin-left: 8px;
                width: auto;
            }
        }

        &__hero-subtitle {
            margin-bottom: 60px;
            font-size: 24px;
            line-height: 31px;
            text-align: left;
        }

        &__hero-pic {
            top: -14px;
            right: -64px;
            width: 54.5%;
            margin: 0;
        }

        &__hero-deco {
            width: 199.2%;
            margin-top: -29%;
            margin-left: 30.4%;
        }
    }
    .logo {
        width: 60px;
        margin: 0;
    }
}

@media (min-width: $desktop) {
    .header {

        &__hero-deco {
            margin-top: -20%;
        }
    }
}
