.bring {
    padding-top: 60px;
    padding-bottom: 60px;

    &__title {
        @include main-font-heavy;
        margin-bottom: 35px;
        text-align: center;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: -0.02em;
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 60px;
    }

    &__item {
        text-align: center;
        margin-bottom: 30px;
    }

    &__item-icon {
        display: inline-block;
        width: 50px;
        margin-bottom: 23px;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__item-text {
        font-size: 15px;
        line-height: 19px;
    }

    &__advantages {

    }

    &__advantages-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 22px;


        &:nth-child(2) {
            .bring__advantages__pic {
                order: 1;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__advantages__text {
        width: 100%;
        max-width: 410px;
        margin-bottom: 30px;

        .bring__title {
            margin-bottom: 0;
            text-align: center;
        }
    }

    &__advantages__pic {
        position: relative;
        width: 67.7%;

        img {
            width: 100%;
            height: auto;
        }
    }
}

@media (min-width: $tablet-landscape) {
    .bring {
        padding-top: 150px;
        padding-bottom: 97px;

        &__title {
            margin-bottom: 50px;
            font-size: 44px;
            line-height: 48px;
        }

        &__list {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 99px;
        }

        &__item {
            margin-bottom: 0;
        }

        &__advantages-row {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 22px;

            &:nth-child(1) {

                .bring__advantages__pic {
                    left: 64px;
                    width: 67.7%;
                }
            }
            &:nth-child(2) {

                .bring__advantages__pic {
                    order: inherit;
                    left: -59px;
                    width: 67%;
                }
            }
            &:nth-child(3) {

                .bring__advantages__pic {
                    left: 58px;
                    width: 69%;
                }
            }
        }

        &__advantages__text {
            width: 30%;
            max-width: 410px;
            margin-bottom: 0;

            .bring__title {
                margin-bottom: 0;
                text-align: left;
            }
        }

        &__advantages__pic {
            width: 67.7%;
        }
    }
}
