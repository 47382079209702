.slider-controls {
    display: flex;
    justify-content: space-between;

    &__btn {
        opacity: 1;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 5px 13px;
        cursor: pointer;
        transition: opacity $animation-time ease-in-out;

        &_left {
            background-image: url("../img/svg/arrow-left.svg");
        }

        &_right {
            background-image: url("../img/svg/arrow-right.svg");
        }

        &:hover {
            opacity: .6;
        }
        &.swiper-button-disabled {
            opacity: .3;
        }
    }
}

@media (min-width: $tablet-landscape) {
    .slider-controls {
        display: flex;
        justify-content: space-between;

        &__btn {
            opacity: 1;
            width: 27px;
            height: 27px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 15px 27px;
            cursor: pointer;
            transition: opacity $animation-time ease-in-out;

            &_left {
                background-image: url("../img/svg/arrow-left.svg");
            }

            &_right {
                background-image: url("../img/svg/arrow-right.svg");
            }

            &:hover {
                opacity: .6;
            }
            &.swiper-button-disabled {
                opacity: .3;
            }
        }
    }
}
