.pricing {
    padding: 60px 0;
    background-image: url(../img/pricing-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        width: 220px;
        margin: 0 0 25px;
        padding: 43px 31px;
        background: #fff;
        backdrop-filter: blur(59px);
        border-radius: 25px;
        font-size: 15px;
        line-height: 19px;

        p {
            margin-bottom: 1px;
        }

        strong {
            @include main-font-heavy;
        }

        a {
            color: $text-purple;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color $animation-time ease-in-out;

            &:hover {
                text-decoration-color: $text-purple;
            }
        }
    }
}
@media (min-width: $tablet-landscape) {
    .pricing {
        padding: 78px 0 89px;

        &__list {
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
        }

        &__item {
            width: auto;
            min-width: 194px;
            margin: 0 64px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
