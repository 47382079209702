
@font-face {
    font-family: 'Futura-Book';
    src: url("../fonts/FuturaPT-Book.woff") format("woff");
    font-display: auto;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura-Heavy';
    src: url("../fonts/FuturaPT-Heavy.woff") format("woff");
    font-display: auto;
    font-weight: normal;
    font-style: normal;
}


