body {
    @include main-font-book;
    color: $text-dark;
    font-size: 15px;
    width: 100vw;
    overflow-x: hidden;
}

.site {
    overflow-x: hidden;

    &__content {
        position: relative;
        z-index: 1;
    }

    &__container {
        overflow: hidden;
    }

    &__centered {
        width: 100%;
        max-width: 1312px;
        padding: 0 16px;
        margin: 0 auto;
    }
    &__btns {
        .btn {
            margin-right: 21px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__title {
        margin-bottom: 35px;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        @include main-font-heavy;
    }
}
@media (min-width: $tablet-landscape) {
    .site {
        &__btns {
            .btn {
                margin-right: 24px;
            }
        }
        &__title {
            margin-bottom: 50px;
            font-size: 50px;
            line-height: 64px;
        }
    }
}
