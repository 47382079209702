.how-it-work {
    padding: 60px 0;

    .site__title {
        margin-bottom: 24px;
    }

    .swiper-container {
        border-radius: 5px;
    }

    .swiper-slide {
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__centered {
        width: 100%;
        max-width: 1094px;
        margin: 0 auto;
        padding: 0 16px;
    }

    &__btns {
        display: flex;
        justify-content: center;
        margin-bottom: 27px;

        button {
            margin-right: 18px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__slider {
        position: relative;
        margin: 0 8px 23px;
        box-shadow: 0 0 60px rgba(34, 50, 74, 0.1);

        .slider-controls {
            @include absoluteCenterY;
            right: -19px;
            left: -19px;
        }
    }
}

.slider__pagination {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0 9px;
    margin-bottom: 26px;

    .swiper-pagination-bullet {
        @include main-font-heavy;
        display: inline-block;
        position: relative;
        width: 15px;
        height: 15px;
        text-align: center;
        color: transparent;
        font-size: 1px;
        cursor: pointer;
        transition-property: color, transform;

        &:after {
            @include absoluteCenter;
            content: "";
            width: 6px;
            height: 6px;
            transition: $animation-time ease-in-out;
            background: #bdb0ff;
            border-radius: 50%;
        }

        &:hover, &.swiper-pagination-bullet-active {
            transform: scale(2);

            &:after {
                color: #fff;
                background: #5a39ff;
            }
        }
    }
}

@media (min-width: $tablet-landscape) {
    .how-it-work {
        padding: 150px 0 14px;

        .site__title {
            margin-bottom: 49px;
        }

        .swiper-container {

            border-radius: 10px;
        }

        .swiper-slide {
            img {
                width: 100%;
                height: auto;
            }
        }

        &__btns {
            margin-bottom: 58px;

            button {
                margin-right: 18px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__slider {
            position: relative;
            margin: 0 0 47px;
            box-shadow: 0 0 60px rgba(34, 50, 74, 0.1);

            .slider-controls {
                @include absoluteCenterY;
                right: -70px;
                left: -70px;
            }
        }
    }

    .slider__pagination {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0;
        margin-bottom: 58px;

        &:before {
            @include absoluteCenterY;
            content: "";
            right: 0;
            left: 0;
            height: 1px;
            background: #bdb0ff;
        }

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            overflow: hidden;
            padding: 3px 1px 3px;
            transition: $animation-time ease-in-out;
            font-size: 10px;
            background: #bdb0ff;
            border-radius: 50%;

            &:after {
                display: none;
            }

            &:hover, &.swiper-pagination-bullet-active {
                color: #fff;
                background: #5a39ff;
            }
        }
    }
}
