.btn {
    @include btnReset();
    display: inline-block;
    height: 47px;
    min-width: 154px;
    padding: 0 16px;
    background: $elements-purple;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px transparent;
    @include main-font-heavy();
    font-size: 14px;
    line-height: 47px;
    text-transform: uppercase;
    text-align: center;
    color: $text-white;
    white-space: nowrap;
    transition: $animation-time ease-in-out;
    transition-property: box-shadow, background-color;

    &:hover:not(:disabled) {
        background: #2E0BE7;
        box-shadow: 0 0 20px rgba(90, 57, 255, 0.7);
    }

    &:disabled {
        cursor: default;
        opacity: .5;
    }
}

.marketplace-btn {
    @include btnReset();
    display: inline-block;
    height: 47px;
    background: #FFF;
    padding: 6px 25px 5px;
    box-shadow: 0 0 23px rgba(38, 132, 255, 0.1);
    border-radius: 5px;
    transition: background $animation-time ease-in-out;

    svg {
        width: 104px;
        height: auto;
    }

    &:hover {
        background: #2681FF;
        svg {
            path, {
                fill: #fff;
            }
            .divider {
                stroke: #fff;
                fill: #fff;
            }
        }
    }

    &_vendor {
        padding: 11px 25px 5px;
    }
}

.outline-btn {
    @include btnReset();
    display: inline-block;
    width: 135px;
    height: 38px;
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 0 5px;
    font-size: 14px;
    line-height: 18px;
    color: $text-purple;
    text-transform: uppercase;
    @include main-font-heavy;
    box-shadow: 0 0 7px rgba(90, 57, 255, 0);
    transition: $animation-time ease-in-out;
    transition-property: box-shadow, border-color, color;

    span {
        filter: none;
    }

    &.active {
        border-color: $elements-purple;
    }
    &:hover {
        border-color: $elements-purple;
        color: #2E0BE7;
        box-shadow: 0 0 7px rgba(90, 57, 255, 0.7);

        span {
            filter: none;
        }
    }
}


@media (min-width: $tablet-landscape) {
    .btn {
        height: 77px;
        min-width: 237px;
        font-size: 20px;
        line-height: 77px;
    }

    .marketplace-btn {
        height: 77px;
        padding: 11px 37px;

        svg {
            width: auto;
            height: auto;
        }

        &_vendor {
            padding: 15px 25px 5px;
        }
    }

    .outline-btn {
        @include btnReset();
        display: inline-block;
        width: 177px;
        height: 64px;
        border: 2px solid transparent;
        border-radius: 5px;
        padding: 0 5px;
        font-size: 20px;
        line-height: 26px;
        color: $text-purple;
        text-transform: uppercase;
        @include main-font-heavy;
        box-shadow: 0 0 7px rgba(90, 57, 255, 0);
        transition: $animation-time ease-in-out;
        transition-property: box-shadow, border-color, color;

        span {
            filter: none;
        }

        &.active {
            border-color: $elements-purple;
        }
        &:hover {
            border-color: $elements-purple;
            color: #2E0BE7;
            box-shadow: 0 0 7px rgba(90, 57, 255, 0.7);

            span {
                filter: none;
            }
        }
    }
}
