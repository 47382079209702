.fancybox-thumbs {
    top: auto;
    width: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95px;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
}

.fancybox-show-thumbs {
    .fancybox-inner {
        right: 0;
        bottom: 95px;
    }
    .fancybox-thumbs {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
    }
}

.fancybox-thumbs__list {
    display: flex;
    flex-wrap: nowrap;

    a:before {
        border-color: $elements-purple;
        border-width: 3px;
        transition: opacity $animation-time ease-in-out;
    }
}

.fancybox-navigation {

    .fancybox-button {
        width: 28px;
        opacity: 1 !important;
        visibility: visible;

        > div {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px 28px;
        }
        &--arrow_left,
        &--arrow_right {
            background-color: transparent;
            padding: 31px 6px 31px 6px !important;

            svg {
                display: none;
            }

            &:disabled {
                opacity: .3!important;
            }
            &:hover {
                opacity: .6 !important;
            }
        }

        &--arrow_left {
            > div {
                background-image: url("../img/svg/arrow-left.svg");
            }
        }
        &--arrow_right {
            > div {
                background-image: url("../img/svg/arrow-right.svg");
            }
        }
    }
}

.compensate-for-scrollbar {
    overflow: auto !important;
}

.fancybox-container {
    display: none;
}

@media (min-width: $desktop) {
    .fancybox-container {
        display: block;
    }
}
