.footer {
    position: relative;
    background: #f4f9ff;
    padding: 30px 0;

    .site__centered {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .marketplace-btn {
        order: 2;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            @include main-font-heavy;
            margin-bottom: 16px;
            color: $text-purple;
            font-size: 14px;
            line-height: 19px;
            text-transform: uppercase;
        }
    }
}

@media (min-width: $tablet-portrait) {
    .footer {
        padding: 50px 0 65px;

        .site__centered {
            flex-direction: row;
            justify-content: space-between;
        }
        .marketplace-btn {
            order: 1;
        }

        &__nav {
            flex-direction: row;
            order: 2;

            a {
                transition: border-color $animation-time ease;
                margin-bottom: 0;
                margin-right: 40px;
                font-size: 16px;
                line-height: 21px;
                border-bottom: 1px solid transparent;

                &:hover {
                    border-color: $text-purple;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
