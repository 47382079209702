.about {
    padding: 60px 0;

    .site__centered {
        position: relative;
    }

    &__text {
        max-width: 628px;
        margin: 0 auto 62px;
        line-height: 1.3;
    }
    &__btns {
        text-align: center;
    }
    &__deco {
        @include absoluteCenter;
        margin-top: 11.7%;
        margin-left: 2.7%;
        z-index: -1;
        width: 134%;

        img {
            width: 100%;
            height: auto;
        }
    }
}

@media (min-width: $tablet-landscape) {
    .about {
        padding: 78px 0 100px;

        .site__centered {
            position: relative;
        }

        &__text {
            max-width: 628px;
            margin: 0 auto 62px;
            line-height: 1.3;
        }
        &__btns {
            text-align: center;
        }
    }
}
